import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Pagination } from "swiper/modules";
import 'swiper/css/pagination';
import Stars from './Stars.js';

const reviews = [
    {
        "id": 1,
        "name": "Jason Allen",
        "star": 5,
        "content": "Fantastic experience. Friendly. Front desk associate is so welcoming. Massage chairs, tv, bar, and overall quiet."
    },
    {
        "id": 2,
        "name": "Jaclyn T",
        "star": 5,
        "content": "Mindy did an Excellent job on my nails! Best in a long while! They were a bit busy and she got up to help fairly often, but I was not in a rush so I did not mind. She was super sweet and very helpful with picking the perfect color I was looking for, too."
    },
    {
        "id": 3,
        "name": "Karen P",
        "title": "Professional and Thorough",
        "star": 5,
        "content": "Love Tip to Toes! We have been coming to this salon for years and years and always have the BEST service! Thank you Tip to Toes!"
    },
    {
        "id": 4,
        "name": "Lauren Scullion",
        "title": "The Perfect Choice!",
        "star": 5,
        "content": "Amazing experience! Michael at the front was friendly when I called ahead for a walk in. Incredibly welcoming and jovial dude. Both Na and Nicole exceeded my expectations with my acrylic fill and pedicure. I'm visiting from out of town but will definitely be back!"
    },
    {
        "id": 5,
        "name": "Diane Schreiber",
        "title": "Very Satisfied!",
        "star": 5,
        "content": "I’ve been here twice and love it… first time I went I did a mani/pedi combo with tips and dip. Looked good for 3 weeks. This last time I got ombré pink and red glitter and had them round my nails as I usually have squared nails. Looks lovely! Thank you Mimi."
    }
];

const Review = () => {
    return (
        <Swiper
            loop={true}
            breakpoints={{
                340: {
                    slidesPerView: 1,
                },
                700: {
                    slidesPerView: 3,
                    spaceBetween: 15
                }
            }}
            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
            }}
            pagination={{
                dynamicBullets: true,
                clickable: true
            }}
            modules={[Autoplay, Pagination]}
        >
            {reviews.map((r) => (
                <SwiperSlide key={r?.id}>
                    <div className="p-2 cursor-pointer lg:p-4">
                        <div className=" flex space-y-4 flex-col items-center justify-center text-center">
                            <div className=" flex flex-col">
                                <h1 className=" text-2xl font-Playfair text-main-color">{r?.name}</h1>
                            </div>
                            <Stars len={r?.star} />
                            <div className="  text-black/80">
                                <span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        className="inline-block h-7 w-7 pr-2"
                                        viewBox="0 0 24 24">
                                        <path
                                            d="M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z" />
                                    </svg>
                                    {r?.content}
                                </span>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default Review;