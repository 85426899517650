const ServicesData = [
    {
        id : 1,
        servicename: 'Manicure & Pedicure',
        service: [
            {
                id : 1,
                name: 'Herbal Green Tea Pedicure/Manicure',
                price: '$56/$40',
                img_url : require('../img/manicure/1.jpg'),
                desc: 'The aromas of this foot bath will open your sinuses and clear your head to pamper your senses. Natural Chinese herbs will increase blood circulatiuon while protecting and restoring moisture followed with a warm neck wrap to soothe any tensions.',
            },
            {
                id : 2,
                name: 'Detox Pedicure/Manicure',
                price: '$74/$41',
                img_url : require('../img/manicure/2.jpg'),
                desc: 'One of our most enjoybable pedicyres. Includes a charcoal soak , purifying scrub , nourishing mask , and neck therapy. this is followed by parrafin wax treatment and a 25 minute feet and shoulder massage using hot stones and emollient massage cream to soften and soothe'
            },
            {
                id : 3,
                name: 'Organic Pedicure/Manicure',
                price: '$70/$38',
                img_url : require('../img/manicure/3.jpg'),
                desc: 'An organic pedicure system with bubbling detox crystal foot bath to eliminate toxins from the body. A pedicure infused with collagen to reduce the eefects of aging skin as the collagen is absorbed by the skin and a relaxing neck therapy and hot stone foot massage to relieve stress'
            },
            {
                id : 4,
                name: 'Basic Pedicure/Manicure',
                price: '$35/$21',
                img_url : require('../img/manicure/4.jpg'),
                desc: 'Foot Soak + Nail Shaping + Cutigie Care + Soft Pumice Foot Scrub + Sugar Exfoliating Scrub + Lotion Foot Massage + Color Polish.'
            }, 
            
            {
                id : 5,
                name: 'Golden Pedicure/Manicure',
                price: '$89/$46',
                img_url : require('../img/manicure/5.jpg'),
                desc : 'Enjoy the most luxurious pedicure utilizing products from Ceremony of gold which includes a gold soak , scrub , and gel mask. The extremely rejuvenating pedicure with 24K gold ingredients to aid the slow down of collagen depletion , increasing of skin elasticity , and stimulating cells to the help tone up the skin. Gold soak with herbal infused foot bath + neck therapy + collagen foot mask + 25 minute feet and shoulder massage wit hot stones'
            },
            {
                id : 6,
                name: 'Peppermint Pedicure/Manicure',
                price: '$51/$30',
                img_url : require('../img/manicure/6.jpg'),
                desc: 'Ease your feet into an aroma therapeutic bath rich with tea tree oil, fragrant eucalyptus oil , and Vitamin-e-all-anti-inflammatory. hen enjoy a lavish scrub of mineral sea salt that polishes the skin. An intense heel smoothing treatment with peppermint cream soothes and refreshes like a gentle breeze. Finish with a moisture restoring warm paraffin treatment and a warm neck wrap'
            },
            {
                id : 7,
                name: 'Citrus or Aloe Vera/Manicure',
                price: '$60/$40',
                img_url : require('../img/manicure/7.png'),
                desc: 'Grapefruit , orange , lemon infused foot bath to eliminate bacterial present on the skin. Our summer citrus pedicure is a symphony of essential oils , vitamin E , and antioxidants formulated to stimulate your senses as it smooth , refines , and c onditions your feet. A fruit rub follows to wipe dead skin and restore moisture. It provides rich vitamins and minerals to give naturally radiant skin with strong healthy nails'
            },
            {
                id : 8,
                name: 'Deluxe Pedicure',
                price: '$43',
                img_url : '',
                desc: 'Foot Soak + Nail Shaping + Cutigie Care + Soft Pumice Foo + Intensive Callus Treatment + Sugar Exfoliating Scrub + Lotion Foot Massage + Color Foo'
            },
            {
                id : 9,
                name: 'Basic Pedicure',
                price: '$36',
                img_url : '',
                desc: 'The basic pedicure includes soak, nail shaping, filling, cuticle trim, buffing, foot massage and polish'
            },
        ],
        img_url : require('../img/manicure/1.jpg'),
    },
    
    {
        id : 2,
        servicename : 'Nail Enhancements',
        service : [
            {
                id : 1,
                name : 'Gel Manicure',
                price: '$40'
            },
            {
                id : 2,
                name : 'Gel Manicure French',
                price: '$45'
            },
            {
                id : 3,
                name : 'Gel Color Change Toe',
                price: '$30'
            },
            {
                id : 4,
                name : 'Gel Color Change Hand',
                price: '$25'
            },
            {
                id : 5,
                name : 'French/ American extra',
                price: '$8'
            },
            {
                id : 6,
                name : 'Color Powder Set/Fill',
                price: '$50+/$40+'
            },
            {
                id : 7,
                name : 'Dipping Pink And White',
                price: '$50'
            },
            {
                id : 8,
                name : 'Solar Pink And White Set/Fill',
                price: '$55+/$50+'
            },
            {
                id : 9,
                name : 'Acrylic Fullset/Refill',
                price: '$40+ / $35+'
            },
            {
                id : 10,
                name : 'Acrylic Color Powder Fullset/Refill',
                price: '$50+ / $40+'
            },
            {
                id : 11,
                name : 'Dip Color',
                price: '$41'
            },
            {
                id : 12,
                name : 'Add Tip',
                price: '$5'
            },
            {
                id : 13,
                name : 'Length',
                price: '$5+'
            },
            {
                id : 14,
                name : 'Shape',
                price: '$5+'
            },
        ],
        img_url : require('../img/6.jpg')
    },
    {
        id : 3,
        servicename: 'Waxing',
        service: [
            {
                name: 'Eyebrow',
                price: '$12'
            },
            {
                name: 'Ears',
                price: '$12'
            },
            {
                name: 'Nose',
                price: '$8'
            },
            {
                name: 'Upper Lip',
                price: '$8'
            },
            {
                name: 'Chin',
                price: '$10'
            },
            {
                name: 'Full Face',
                price: '$45'
            },
            {
                name: 'Full Arm / Fore Arms',
                price: '$45 / $30'
            },
            {
                name: 'Under Arm',
                price: '$25'
            },
            {
                name: 'Full Legs',
                price: '$65'
            },
            {
                name: 'Calves',
                price: '$42'
            },
            {
                name: 'Back',
                price: '$55'
            },
            {
                name: 'Brazilian',
                price: '$50'
            },
            {
                name: 'Bikini',
                price: '$37'
            },
            {
                name: 'Ear Candle',
                price: '$20'
            },
        ],
        img_url : require('../img/wax.jpg')
    },
    {
        id : 4,
        servicename: 'Eyelash Extension',
        service: [
            {
                name : 'Cluster New Set (No Refill)',
                price : '$60',
                img_url: require('../img/eyelash/4.jpg')
            },
            {
                name : 'Eyelash Tint / Eyebrow Tint',
                price : '$25',
            },
            {
                name : 'Cluster New Set (No Refill)',
                price : '$60',
                img_url: require('../img/eyelash/4.jpg')
            },
            {
                name : 'Classic New Set',
                price : '$110',
                desc: 'Classic Refill 2 Week / 3 Week/ 4 Week',
                desc1: '$60/$70/$95',
                img_url: require('../img/eyelash/7.jpg')
            },
            {
                name : 'Wispy/Mega New Set',
                price : '$175',
                desc: 'Wispy/Mega Refill 2 Week / 3 Week/ 4 Week',
                desc1: '$75/$100/$135',
                img_url: require('../img/eyelash/1.jpg')
            },
            {
                name : 'Volume New Set',
                price : '$140',
                desc: 'Volume Refill 2 Week / 3 Week/ 4 Week',
                desc1: '$70/$90/$120',
                img_url: require('../img/eyelash/6.jpg')
            }
        ],
        img_url: require('../img/eyelash/5 (2).jpg')
    },
    {
        id : 5,
        servicename: 'Extra',
        service: [
            {
                name : 'Nail Design / Repair',
                price : '$5+ / Each Nail / $7+'
            },
            {
                name : 'Polish Change Hand / Toe',
                price : '$10 / $15'
            },
            {
                name : 'Polish Change Hand / Toe French',
                price : '$12 / $18'
            },
            {
                name : 'Parafin Wax',
                price : '$7'
            },
            {
                name : 'Collagen Mask',
                price : '$8'
            },
            {
                name : 'Nail Removal W/O Service',
                price : '$15'
            },
            {
                name : 'Nail Removal With Service',
                price : '$5'
            },
        ],
        img_url: require('../img/extra.png')
    },
    {
        id : 6,
        servicename: 'Kid Service',
        service: [
            {
                name : 'Manicure',
                price : '$12'
            },
            {
                name : 'Pedicure',
                price : '$22'
            },
            {
                name : 'Polish Hand/ Toe',
                price : '$6/$12'
            },
        ],
        img_url : require('../img/kid.png')
    },
];

export default ServicesData;