import React from "react";
import spapic from "../img/test.png"
export default function Contact(){

    return (
        <section className = "space-y-5 pb-5 md:pb-12 ">
            <div className="bg-bg6 bg-no-repeat bg-center bg-cover h-[300px] lg:h-[600px] relative">
                <div className="top-8 text-white w-full absolute text-3xl h-full flex items-center justify-center cursor-pointer lg:text-6xl">
                    <h2 style={{fontFamily: "Redressed"}} className="">Contact Us</h2>
                </div>
            </div>
            <div className="mx-auto max-w-screen-xl cursor-pointer">
                <div className="grid grid-cols-1 text-justify md:gap-8 lg:grid-cols-3 ">
                    <div className="lg:col-span-2 rounded-lg md:w-full w-screen p-4 space-y-4 h-full lg:space-y-8 transition ease-in-out duration-200 delay-100 hover:opacity-50">
                        <img className=" lg:h-full h-[400px]  rounded-lg" src={spapic} alt="" />
                    </div>
                    <div className="p-2 space-y-4 lg:space-y-8 lg:text-justify lg:grid justify-center items-center text-center">
                        <div className="">
                            <div className="grid grid-cols-1 p-2 space-y-5">
                                <div>
                                    <span className="text-3xl text-main-color font-Playfair tracking-wider">See us in person</span>
                                </div>
                                <div className="space-y-3">
                                    <p className="">We love our customers and welcome them to visit during our normal business hours of 10am to 7:00pm, Monday through Friday.</p>
                                    <ul className="space-y-2 font-semibold">
                                        <li>
                                            <a className="transition text-black t-underline t-underline-black border-b-yellow-500" target="_blank"  rel="noreferrer" href = "https://maps.app.goo.gl/N2gEyfKgCA3hXVHeA">
                                                Address: 3308 Preston Rd #315, Plano, TX 75093
                                            </a>
                                        </li>
                                        <li>
                                            <a className="transition text-black t-underline t-underline-black border-b-yellow-500" href="tel:9729644704">
                                                Phone#: (972) 964-4704
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <hr></hr>
                                <div>
                                    <div className="font-semibold text-lg">Business Hours:</div>
                                    <ul className="space-y-4 text-sm mt-4">
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Mon: 10:00 am - 7:00 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Tues:  10:00 am - 7:00 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Wed:  10:00 am - 7:00 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Thu:  10:00 am - 7:00 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Fri:  10:00 am - 7:00 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Sat: 9:00 am - 7:00 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span href="" className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Sun: 12:00 am - 6:00 pm
                                            </span>
                                        </li>
                                    </ul>
                                    <button onClick={(e) => {
                                        e.preventDefault();
                                        window.open('https://www.fresha.com/a/tip-to-toe-nail-plano-plano-3308-preston-road-yl2a8fyw', '_blank');
                                    }} className=" mt-5 py-3 transition ease-in-out duration-200 delay-100 group border-main-color hover:bg-main-color px-12 border-[1px]">
                                        <span className=" transition ease-in-out duration-200 delay-100 text-lg group-hover:text-white">
                                            Book Now
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid justify-items-center">
                <iframe className="w-full md:px-12" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d26755.855978961215!2d-96.8302786!3d33.0437751!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c22e287eb3f83%3A0x972b4d833fa411d7!2sTip%20To%20Toe%20Nails%20Plano!5e0!3m2!1sen!2s!4v1727024152283!5m2!1sen!2s"  height="600" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </section>
    )
}