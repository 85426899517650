import { React, useState } from "react";
import Logo1 from "../img/Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsStaggered, faXmark, faPhone, faLocationDot } from "@fortawesome/free-solid-svg-icons"


function Navbar(props) {
    const [Click, setClick] = useState(false);
    const [Scroll, SetScroll] = useState(false);

    const Change_color = () => {
        if (window.scrollY >= 10) {
            SetScroll(true);
        }
        else { SetScroll(false); }
    };
    window.addEventListener('scroll', Change_color)
    const Icon_Style = { fontSize: '22', };
    return (
        <div className="">
            <nav className={Scroll ? "bg-white shadow-lg px-4 transition ease-out duration-200  delay-100 flex font-header-font justify-between lg:justify-around w-full fixed top-0 left-0 right-0 z-10 " :
                "flex text-black justify-between lg:justify-around bg-none font-header-font w-screen fixed px-4 top-0 left-0 right-0 z-10"}>
                <div className="flex items-center cursor-pointer group">
                    <img src={Logo1} alt="" className= {Scroll? " w-24 md:w-24 group-hover:opacity-70": "w-24 md:w-44 group-hover:opacity-70"} />
                </div>
                <div className={Scroll ? "lg:flex text-md hidden items-center space-x-20 text-xl capitalize text-black" :
                    "lg:flex text-md hidden items-center space-x-20 text-xl capitalize text-black"}>
                    <a onClick={() => props.handleTabClick("Home")} className={`${props.isActive("Home")} flex hover:text-main-color transition ease-in-out delay-150 duration-200`} href="/">
                        Home
                    </a>
                    <a onClick={() => props.handleTabClick("Services")} className={`${props.isActive("Services")} flex hover:text-main-color transition ease-in-out delay-150 duration-200`} href="/Services">
                        Services
                    </a>
                    <a onClick={() => props.handleTabClick("Gallery")} className={`${props.isActive("Gallery")} flex hover:text-main-color transition ease-in-out delay-150 duration-200`} href="/Gallery">
                        Gallery
                    </a>
                    <a onClick={() => props.handleTabClick("Contact")} className={`${props.isActive("Contact")} flex hover:text-main-color transition ease-in-out delay-150 duration-200`} href="/Contact">
                        Contact
                    </a>
                </div>
        
                <div className={Scroll ? "lg:hidden text-3xl cursor-pointer flex items-center space-x-4" :
                    "lg:hidden space-x-4 text-3xl cursor-pointer flex items-center"}>
                    <div onClick={() => setClick(!Click)} className={Scroll ? "lg:hidden transition text-3xl cursor-pointer flex items-center text-main-color space-x-4" : "lg:hidden space-x-4 blkock text-3xl cursor-pointer flex items-center text-main-color"}>
                        {!Click ?
                            <FontAwesomeIcon className=" text-main2" icon={faBarsStaggered} /> :
                            <FontAwesomeIcon className=" text-main2" icon={faXmark} />
                        }
                    </div>
                </div>
                <div className={Scroll ? "hidden lg:flex items-center space-x-12 text-black" :
                    "hidden lg:flex items-center space-x-12 text-white"}>
                    <button
                        onClick={() => {
                            window.location.href = 'https://www.fresha.com/a/tip-to-toe-nail-plano-plano-3308-preston-road-yl2a8fyw';
                        }}
                        className= {Scroll? "transition ease-in-out duration-200 delay-100 group border-main-color hover:bg-main-color border-[1px] group cursor-pointer px-4 py-3 capitalize":"hover:bg-main-color hover:opacity-60 duration-200 ease-in-out delay-100 bg-main-color group cursor-pointer text-white px-4 py-3 capitalize"}>
                        <a className = {Scroll?"group-hover:text-white transition ease-in-out duration-200 tracking-wider uppercase":"tracking-wider uppercase"}>Contact Us</a>
                    </button>
                </div>
            </nav>
            {Click ?
                <nav className="overflow-hidden space-y-12 md:space-y-24 top-20 md:top-28 fixed p-6 w-screen h-screen bg-white transition duration-700 ease-out lg:hidden left-0 right-0 z-20  mx-auto  text-black">
                    <div className=" space-y-16 md:space-y-24 flex uppercase flex-col text-lg mt-12">
                        <a onClick={() => setClick(!Click)} className="" href="/">Home</a>
                        <a onClick={() => setClick(!Click)} className="" href="/Services">Services</a>
                        <a onClick={() => setClick(!Click)} className="" href="/Gallery">Gallery</a>
                        <a onClick={() => setClick(!Click)} className="" href="/Contact">Contact</a>
                        <button onClick={() => window.location.href = 'https://www.fresha.com/a/tip-to-toe-nail-plano-plano-3308-preston-road-yl2a8fyw'}
                            className="hover:bg-black group  transition ease-out duration-200 cursor-pointer px-4 py-3 border-black border-2 capitalize tracking-wide">
                            <a target="_blank" href="tel:9729644704" className="group-hover:text-black">Contact Us</a>
                        </button>
                    </div>
                </nav>
                : null
            }
        </div>
    );
};


export default Navbar;