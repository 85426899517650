import './App.css';
import React , {useState , useEffect} from 'react';
import Navbar from './Components/Navbar';
import Home from './Views/Home';
import { Route, Routes, Navigate , useLocation} from 'react-router-dom';
import FloatBtn from "./Components/Floatbtn";
import Footer from './Components/Footer';
import Contact from './Views/Contact';
import Services from './Views/Services';
import { Fade } from 'react-awesome-reveal';
import Gallery from './Views/Gallery';

function App() {

	const [activeTab, setActiveTab] = useState("Home");

	const handleTabClick = (tabName) => {
		setActiveTab(tabName);
	};

	const isActive = (tabName) => {
		return activeTab === tabName ? " text-main-color border-t-hover-btn border-t-2 p-2" : "";
	};

	const location = useLocation();

	useEffect(() => {

    const currentPath = location.pathname;

    switch (currentPath) {
		case "/Contact":
			setActiveTab("Contact");
			break;

		case "/Gallery":
			setActiveTab("Gallery");
			break;

		case "/Services":
			setActiveTab("Services");
			break;

		default:
			setActiveTab("Home");
			break;

		}
		
	}, [location]);

	return (
		<main className=' font-Lato overflow-x-hidden'>
		<Navbar activeTab = {activeTab} setActiveTab = {setActiveTab} handleTabClick = {handleTabClick} isActive = {isActive} />
		
		<Fade>
			<Routes>
			<Route path = "/" element = {<Home activeTab = {activeTab} setActiveTab = {setActiveTab} handleTabClick = {handleTabClick} isActive = {isActive} />} />
			<Route path = "/Gallery" element = {<Gallery activeTab = {activeTab} setActiveTab = {setActiveTab} handleTabClick = {handleTabClick} isActive = {isActive} />} />
			<Route path='/Contact' element = {<Contact activeTab = {activeTab} setActiveTab = {setActiveTab} handleTabClick = {handleTabClick} isActive = {isActive}/>} />
			<Route path = "/Services" element = {<Services activeTab = {activeTab} setActiveTab = {setActiveTab} handleTabClick = {handleTabClick} isActive = {isActive}/>} />
			<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		</Fade>

		<FloatBtn />
		<Footer />
		</main>
	);
}

export default App;